import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DataTable,
  DataTableBody,
  DataTableCodeBlock,
  DataTableValueBlock,
  DataTableHeader,
  DataTableRow,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = () => {
  const data = useStaticQuery(graphql`
    query elevationsGuidelinesCode {
      allElevationsYaml {
        edges {
          node {
            elevations {
              name
              value
              css_var
            }
          }
        }
      }
    }
  `);

  return (
    <PlatformTab>
      <Paragraph className="uni-padding--two--bottom">
        Use the values below.
      </Paragraph>

      <Section title="Content">
        <Paragraph>
          Use z-index values within the content area of your interface.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation => elevation.name.startsWith('Content'))
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.css_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.css_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Component">
        <Paragraph>
          When creating custom components, match to the component name below to
          ensure you don't run into stacking issues with Uniform components.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation => !elevation.name.startsWith('Content'))
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.css_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.css_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;

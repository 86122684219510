import React from 'react';
import WebTab from './_code-web';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Elevation"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Elevation"
      />

      <PlatformTabs pageType="guidelines" platforms={{ web: <WebTab /> }} />
    </PageWithSubNav>
  );
};

export default IndexPage;
